<template>
  <div class="modal sign-modal">
    <div class="overlay" @click="$emit('closeSignInModal')"></div>
    <div class="wrapper">
      <div class="container">
        <a href="index.html" class="logo">
          <img src="./../img/logo.svg" class="img" />
        </a>
        <div class="form">
          <div class="form-wrapper">
            <img class="close" @click="$emit('closeSignInModal')" src="./../img/prev.svg" />
            <div :class="['form-fields login-content', { hidden: !loginContentVisible }]">
              <div class="form-fields-wrapper">
                <div class="title regular">
                  <b>{{ $t("Sign in") }}</b>
                </div>
                <div class="flex">
                  <div class="desc small">
                    <b>{{ $t('Don’t have an account?') }}</b>
                  </div>
                  <a @click="$parent.openSignUpModal" class="desc small green2">
                    <b>{{ $t('Sign Up') }}</b>
                  </a>
                </div>
                <label>
                  <div class="desc small">{{ $t('E-mail:') }}</div>
                  <input type="email" :placeholder="$t('Email')" v-model="email" />
                </label>
                <label>
                  <div class="desc small">{{ $t('Password:') }}</div>
                  <input type="password" :placeholder="$t('Password')" v-model="pass" />
                </label>
                <a class="desc small green2" @click="switchToforgotPass">
                  <b>{{ $t("Forgot password?") }}</b>
                </a>
                <div class="cta-container">
                  <button class="button orange" @click="submitLogin">
                    <span>{{ $t("Sign in") }}</span>
                  </button>
                </div>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">
                    {{ $t($parent.error) }}
                  </div>
                </transition>
              </div>
            </div>
            <div :class="['form-fields forgot-pass-content', { hidden: !forgotPassContentVisible }]">
              <div class="form-fields-wrapper">
                <div class="title regular">{{ $t("Forgot password") }}?</div>
                <div class="flex"></div>
                <label>
                  <div class="desc">{{ $t("Email") }}:</div>
                  <input type="email" :placeholder="$t('Email')" v-model="email" />
                </label>
                <div class="cta-container">
                  <button class="button" @click="submitForgotPass">
                    <span>{{ $t("Recover") }}</span>
                  </button>
                </div>
                <transition name="fade">
                  <div v-if="$parent.successMessage" class="desc green">
                    {{ $t($parent.successMessage) }}
                  </div>
                </transition>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">
                    {{ $t($parent.error) }}
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignInModal",
  props: {
    isVerified: Boolean
  },
  components: {},
  data: function () {
    return {
      loginContentVisible: true,
      forgotPassContentVisible: false,
      email: "",
      pass: "",
    };
  },
  mounted() {},
  methods: {
    switchToforgotPass() {
      this.$parent.clearError();
      setTimeout(() => {
        this.loginContentVisible = false;
        this.forgotPassContentVisible = true;
      }, 100);
    },
    switchToLogin() {
      this.$parent.clearError();
      setTimeout(() => {
        this.forgotPassContentVisible = false;
        this.loginContentVisible = true;
      }, 100);
    },
    submitLogin() {
      let loginData = {
        username: this.email,
        password: this.pass,
      };
      this.$emit("login", loginData);
    },
    submitForgotPass() {
      let data = {
        email: this.email,
      };
      this.$emit("forgotPass", data);
    },
  },
};
</script>
