<template>
  <footer class="footer">
    <div class="footer-section">
      <div class="wrapper">
        <div class="left">
          <a href="index.html" class="logo">
            <img src="./../img/logo.svg" class="img" />
          </a>
          <div class="desc small">
            {{ $t($parent.footerRequisites) }}
          </div>
        </div>
        <div class="right">
          <ul class="nav">
            <template v-for="item in $parent.textPageList">
              <li class="nav__item desc small" :key="item.id" v-if="item.title">
                <a @click="$parent.goToPage(item.id)">
                  <b>{{ $t(item.title) }}</b>
                </a>
              </li>
            </template>
            <li class="nav__item desc small">
                <router-link to="/contacts">
                  <b>{{ $t('Contact us') }}</b>
                </router-link>
              </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="copyright-section">
      <div class="wrapper">
        <div class="left">
          <div class="desc small">
            {{ $t($parent.footerCopyright) }}
          </div>
        </div>
        <div class="right">
          <div class="list">
            <div class="item">
              <img src="./../img/visa.svg" class="img" />
            </div>
            <div class="item">
              <img src="./../img/mc_symbol.svg" class="img" />
            </div>
            <div class="item">
              <img src="./../img/3ds.svg" class="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: [],
  data: function () {
    return {
      paymentMethodsIsVisible: false,
      email: '',

    };
  },
  methods: {
    submit() {
      let data = {
        "email": this.email,
      }
      this.$emit('submitSubscribtion', data);
    },
  },
  mounted() {},
};
</script>
