<template>
  <main class="main product-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <transition name="fade">
            <div class="product-preview" v-if="$parent.productPageItem">
              <div class="preview-container">
                <div class="preview-wrapper">
                  <div class="preview">
                    <transition name="fade">
                      <span class="title" v-if="$parent.addToCartChosenItem == $parent.productPageItem.id">{{ $t('Added') }}</span>
                    </transition>
                    <img :src="$parent.imgDomain + $parent.productPageItem.img_url" class="img"/>
                  </div>
                  <div class="text">
                    <div class="desc small">{{ $parent.productPageItem.type }}</div>
                    <div class="title regular">{{ $parent.productPageItem.title }}</div>
                    <div class="desc small">
                      {{ $parent.productPageItem.quality }}
                    </div>
                    <div class="title price green">
                      {{ $parent.productPageItem.price }}
                      <span class="currency">{{ $parent.currency }}</span>
                    </div>
                    <button class="button long" @click="$parent.addToCart($parent.productPageItem, $parent.productPageItem.id)">
                      <span>{{ $t("Add to cart") }}</span>
                    </button>
                    <transition name="slide">
                      <div 
                        v-if="$parent.error" 
                        class="error-desc desc red"
                      >
                        {{ $t($parent.error) }}
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </section>
      <section class="section similar-section">
        <div class="wrapper">
          <div class="title">
            <b>{{ $t('Similar offers') }}</b>
          </div>
          <div class="carousel-container product-list">
            <SlickItem v-if="$parent.productPageItem.similar.length" :list="$parent.productPageItem.similar" :currency="currency" />
            <router-link to="/products/all" class="desc small link green2">
              <b>
                <span>{{ $t('See all') }}</span>
              </b>
            </router-link>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import SlickItem from '../components/SlickItem.vue';

export default {
  name: "ProductPage",
  props: ["addToCartChosenItem", "currencyCode", "currency"],
  components: {
    SlickItem
  },
  data: function () {
    return {};
  },
  created() {},
  mounted() {
    this.$parent.getProductPageContent(this.$route.params.id);
  },
  methods: {
    addToCart(item, id) {
      this.$parent.addToCart(item, id);
    },
    goToProduct(item) {
      this.$parent.goToProduct(item);
    },
  },
  watch: {
    currencyCode() {
      this.$parent.getProductPageContent(this.$route.params.id);
    }
  },
  computed: {
  }
};
</script>
