<template>
	<div class="modal heroes-modal">
    <div class="overlay" @click="$parent.closeHeroesModal"></div>
    <div class="wrapper">
      <div class="container">
       <img src="./../img/close.svg" @click="$parent.closeHeroesModal" class="close"/>
        <div class="list" v-if="$parent.heroOptions">
          <div 
            class="title extrasmall" 
            v-for="(item, i) in $parent.heroOptions" 
            :key="i"
            @click="$parent.pickHero(item)"
          >
            <b>{{ $t(item) }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
	name: 'HeroesModal',
	data: function() {
		return {
		}
	},
	methods: {
	
	},
	mounted() {
  },
  computed: {
    
  }
}
</script>
