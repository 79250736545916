<template>
    <div class="modal topup-modal sell-skin-modal">
      <div
        class="overlay"
        @click="$parent.closeSellSkinModal"
      />
      <div class="wrapper">
        <div class="container">
          <div class="form">
            <div class="form-wrapper">
              <img
                src="./../img/close.svg"
                class="close"
                alt=""
                @click="$parent.closeSellSkinModal"
              >
              <ValidationObserver
                ref="form"
                v-slot="{ handleSubmit, touched, valid }"
                class="form-fields"
                tag="div"
              >
                <form
                  class="form-fields-wrapper"
                  @submit.prevent="handleSubmit(submit)"
                >
                  <div class="cart-up">
                    <div class="cart-top">
                      <div class="title">
                        <b>{{ $t('Sell your skin') }}</b>
                      </div>
                    </div>
                    <div class="topup-form">
                      <div class="desc">
                        {{ $t('Check my trade link') }} <a target="_blank" href="https://steamcommunity.com/profiles/76561198080854701/tradeoffers/privacy">https://steamcommunity.com/profiles/76561198080854701/tradeoffers/privacy</a>
                      </div>
  
  
                      <ValidationProvider
                        v-slot="{ errors }"
                        :rules="{ regex: /^https:\/\/[a-zA-Z0-9\-\.]+(\.[a-zA-Z]{2,})(:[0-9]{1,5})?(\/.*)?$/ }"
                        vid="steam_link"
                        name="Steam trade link"
                        slim
                      >
                        <label class="input-container">
                          <div class="desc">
                            {{ $t('Steam trade link') }}
                          </div>
                          <input
                            v-model="steam_link"
                            type="text"
                            required
                            :placeholder="$t('Steam trade link')"
                          >
                          <span
                            v-if="errors && errors.length"
                            class="error"
                          >{{ errors[0] }}
                          </span>
                        </label>
                      </ValidationProvider>
  
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required"
                        vid="skin_name"
                        name="Skin name"
                        slim
                      >
                        <label class="input-container">
                          <div class="desc">
                            {{ $t('Skin name') }}
                          </div>
                          <input
                            v-model="skin_name"
                            type="text"
                            required
                            :placeholder="$t('Skin name')"
                          >
                          <span
                            v-if="errors && errors.length"
                            class="error"
                          >{{ errors[0] }}
                          </span>
                        </label>
                      </ValidationProvider>
  
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required|numeric|min:0.1"
                        vid="price"
                        name="price"
                        slim
                      >
                        <label class="input-container">
                          <div class="desc">
                            {{ $t('Price') }}
                          </div>
                          <input
                            v-model="price"
                            type="tel"
                            required
                            :placeholder="$t('Price')"
                          >
                          <span
                            v-if="errors && errors.length"
                            class="error"
                          >{{ errors[0] }}
                          </span>
                        </label>
                      </ValidationProvider>
                      <!--                    <div class="cta-container">-->
                      <!--                      <div class="chekbox-container">-->
                      <!--                        <label class="chekbox-label">-->
                      <!--                          <div class="chekbox-label-wrapper">-->
                      <!--                            <input-->
                      <!--                                v-model="terms"-->
                      <!--                                type="checkbox"-->
                      <!--                                name="terms"-->
                      <!--                            >-->
                      <!--                            <div class="checkbox" />-->
                      <!--                            <span class="title">{{ $t('I agree with') }} </span>-->
                      <!--                            <a-->
                      <!--                                class="title"-->
                      <!--                                @click="$parent.goToPage('privacy')"-->
                      <!--                            > {{ $t('privacy policy') }}</a>-->
                      <!--                            <span class="title"> {{ $t('and') }} </span>-->
                      <!--                            <a-->
                      <!--                                class="title"-->
                      <!--                                @click="$parent.goToPage('terms')"-->
                      <!--                            > {{ $t('terms and conditions') }}</a>-->
                      <!--                          </div>-->
                      <!--                        </label>-->
                      <!--                      </div>-->
                      <!--                    </div>-->
                    </div>
                  </div>
                  <div class="cart-bottom">
                    <button
                      :disabled="!touched || !valid"
                      :class="['button blue', {'disabled': !touched || !valid}]"
                      type="submit"
                    >
                      {{ $t('Submit') }}
                    </button>
                  </div>
                  <transition name="slide">
                    <div
                      v-if="$parent.error"
                      class="error-desc desc red"
                    >
                      {{ $t($parent.error) }}
                    </div>
                  </transition>
                  <transition name="slide">
                    <div
                      v-if="success"
                      class="error-desc desc green"
                    >
                      {{ $t(success) }}
                    </div>
                  </transition>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  import axios from "@/axios";
  import {ValidationObserver, ValidationProvider} from "vee-validate";
  
  export default {
    name: 'SellSkinModal',
    components: {
      ValidationObserver,
      ValidationProvider
    },
    props: [],
    data: function() {
      return {
        steam_link: '',
        skin_name: '',
        price: '',
        success: ''
        // terms: ''
      }
    },
    computed: {
      requiredRegisterFieldsAreFilled() {
        let fieldsAreFilled;
        if(
            this.terms
        ) {
          fieldsAreFilled = true;
        } else {
          fieldsAreFilled = false;
        }
        return fieldsAreFilled;
      }
    },
    mounted() {
      // this.getCounties();
      // this.$http.get(process.env.VUE_APP_API + 'user/profile')
      //     .then((res) => {
      //       this.price = res.data.phone;
      //       this.steam_link = res.data.name;
      //       this.skin_name = res.data.surname;
      //       this.countryId = res.data.country;
      //       this.city = res.data.city;
      //       this.address = res.data.address;
      //       this.postCode = res.data.zip;
      //
      //       if (this.countries && this.countries.length && this.countryId) {
      //         this.setCountry();
      //       }
      //     })
      //     .catch((res) => {
      //       if(res.response.status === 401) {
      //         this.$parent.openSignIn();
      //       }
      //     })
    },
    methods: {
      setCountry() {
        let mapped = this.countries.map(({
                                           id,
                                           title
                                         }) => ({
          id,
          title
        }));
        this.countries = mapped;
        this.selectedCountry = this.countries.find(item => item.id == this.countryId);
  
        for (let i = 0; i < this.countryOptions.length; i++) {
          if (this.countryOptions[i] == this.selectedCountry.name) {
            this.countryId = this.countryOptionsId[i];
          }
        }
  
      },
      selectCountry(event) {
        this.country = event.text;
        for (let i = 0; i < this.countryOptions.length; i++) {
          if (this.countryOptions[i] == event.text) {
            this.countryId = this.countryOptionsId[i];
          }
        }
      },
      getCounties() {
        this.$http.get(process.env.VUE_APP_API + 'countries')
            .then((res) => {
              let arr = res.data.payload;
              let newCountries = arr.map(function(item) {
                return item['title'];
              });
              let newCountriesId = arr.map(function(item) {
                return item['id'];
              });
              this.countryOptionsId = newCountriesId;
              this.countryOptions = newCountries;
              this.countries = arr;
              let mapped = this.countries.map(({
                                                 id,
                                                 title
                                               }) => ({
                id,
                title
              }));
              this.countries = mapped;
  
              if (this.countries && this.countries.length && this.countryId) {
                this.setCountry();
              }
            })
            .catch((res) => {
              if(res.response.status === 401) {
                this.$parent.openSignIn();
              }
            })
      },
      allowOnlyNumbers(){
        this.amount = this.amount.replace(/[^0-9.,]/g, '');
  
        // Split the input into integer and decimal parts
        const parts = this.amount.split(/[.,]/);
        const integerPart = parts[0];
        let decimalPart = parts[1];
  
        // Limit decimal part to two digits
        if (decimalPart !== undefined && decimalPart.length > 2) {
          decimalPart = decimalPart.slice(0, 2);
        }
  
        // Concatenate the integer and decimal parts back
        this.amount = decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
      },
      async submit() {
        try {
          await axios.post('sell-orders', {
            skin_name: this.skin_name,
            steam_link: this.steam_link,
            price: this.price,
          });
          let self = this;
          this.success = 'Success'
          setTimeout(function() {
            self.$parent.closeSellSkinModal();
          },3000)
        } catch (error) {
          if (error.response && error.response.data.errors) {
            this.$refs.form.setErrors(error.response.data.errors);
          } else if (error.response.data.status === 'ERROR' && error.response.data.message) {
            this.$refs.form.setErrors({
              'price': [error.response.data.message]
            });
          }
        }
      },
      send() {
        let data = {
          "name": this.steam_link,
          "surname": this.skin_name,
          "phone": this.price,
          "amount": this.amount,
          "country": this.selectedCountry.id,
          "city": this.city,
          "address": this.address,
          "postCode": this.postCode
        }
  
        this.$emit('openOrderModal', data);
      }
    }
  }
  </script>