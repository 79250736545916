<template>
    <div class="modal topup-modal">
      <div class="overlay" @click="$parent.closeWithdraw" />
      <div class="wrapper">
        <div class="container">
          <div class="form">
            <div class="form-wrapper">
              <img
                src="./../img/close.svg"
                class="close"
                @click="$parent.closeWithdraw"
              />
              <div class="form-fields">
                <ValidationObserver
                  ref="form"
                  v-slot="{ handleSubmit, touched, valid }"
                  slim
                >
                  <div class="form-fields-wrapper">
                    <div class="cart-up">
                      <div class="cart-top">
                        <div class="title">
                          <b>{{ $t("WITHDRAW") }}</b>
                        </div>
                      </div>
                      <div class="topup-form">
                        <template v-if="step === 2">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            vid="amount"
                            name="amount"
                            slim
                          >
                            <label class="input-container">
                              <div class="desc">
                                {{ $t("Amount") }}
                              </div>
                              <input
                                v-model="amount"
                                type="text"
                                required
                                :placeholder="$t('Amount')"
                              />
                              <span v-if="errors && errors.length" class="error"
                                >{{ $t(errors[0]) }}
                              </span>
                            </label>
                          </ValidationProvider>
                        </template>
  
                        <template v-if="step === 3">
                          <ValidationProvider
                            v-slot="{ errors }"
                            :rules="{
                              required: true,
                              regex: /^\d{4}\s\d{4}\s\d{4}\s\d{4}$/,
                            }"
                            vid="pan"
                            name="Card number"
                            slim
                          >
                            <label class="input-container">
                              <div class="desc">
                                {{ $t("Card number") }}
                              </div>
                              <input
                                v-model="formattedPan"
                                type="text"
                                required
                                :placeholder="$t('Card number')"
                                @input="
                                  formattedPan = formatCardNumber(
                                    $event.target.value
                                  );
                                  resetErrorOnCardNumberChange();
                                "
                              />
                              <span v-if="errors && errors.length" class="error"
                                >{{ $t(errors[0]) }}
                              </span>
                            </label>
                          </ValidationProvider>
  
                          <ValidationProvider
                            v-slot="{ errors }"
                            :rules="{ required: true, regex: /^\d{2}\/\d{2}$/ }"
                            vid="expiration_date"
                            name="Expiration Date"
                            slim
                          >
                            <label class="input-container">
                              <div class="desc">
                                {{ $t("Expiration Date") }} (MM/YY)
                              </div>
                              <input
                                v-model="expiration_date"
                                type="text"
                                required
                                :placeholder="$t('Expiration Date') + '(MM/YY)'"
                                @input="
                                  expiration_date = formatExpirationDate(
                                    $event.target.value
                                  );
                                  resetErrorOnExpirationDateChange();
                                "
                              />
                              <span v-if="errors && errors.length" class="error"
                                >{{ $t(errors[0]) }}
                              </span>
                            </label>
                          </ValidationProvider>
  
                          <ValidationProvider
                            v-slot="{ errors }"
                            :rules="{
                              required: true,
                              regex: /^[A-Za-z]+\s[A-Za-z]+$/,
                            }"
                            vid="card_holdername"
                            name="Card Holder Name"
                            slim
                          >
                            <label class="input-container">
                              <div class="desc">
                                {{ $t("Card Holder Name") }}
                              </div>
                              <input
                                v-model="card_holdername"
                                type="text"
                                required
                                :placeholder="$t('Card Holder Name')"
                                @input="
                                  card_holdername = formatCardHolderName(
                                    $event.target.value
                                  )
                                "
                              />
                              <span v-if="errors && errors.length" class="error"
                                >{{ $t(errors[0]) }}
                              </span>
                            </label>
                          </ValidationProvider>
  
                          <transition name="slide">
                            <div v-if="$parent.error" class="error-desc desc red">
                              {{ $t($parent.error) }}
                            </div>
                          </transition>
  
                          <ValidationProvider
                            :rules="{ required: { allowFalse: false } }"
                            vid="terms"
                            slim
                          >
                            <div class="cta-container">
                              <div class="chekbox-container">
                                <label class="chekbox-label">
                                  <div class="chekbox-label-wrapper">
                                    <input
                                      v-model="terms"
                                      type="checkbox"
                                      name="terms"
                                    />
                                    <div class="checkbox" />
                                    <span class="title">{{
                                      $t("I agree with")
                                    }}</span>
                                    <a
                                      class="title link"
                                      @click="$parent.goToPage('privacy')"
                                    >
                                      {{ $t("privacy policy") }}</a
                                    >
                                    <span class="title"> {{ $t('and') }} </span>
                                    <a
                                      class="title link"
                                      @click="$parent.goToPage('terms')"
                                    >
                                      {{ $t("terms and conditions") }}</a
                                    >
                                  </div>
                                </label>
                              </div>
                            </div>
                          </ValidationProvider>
                        </template>
                      </div>
                    </div>
                    <div class="cart-bottom">
                      <button
                        :disabled="!valid"
                        :class="['button blue', { disabled: !valid }]"
                        @click="proceed"
                      >
                        {{ $t("Withdraw") }}
                      </button>
                      <div class="desc">{{ $parent.currency }} {{ amount }}</div>
                    </div>
  
                    <div class="desc desc-secure">
                      {{
                        $t(
                          "We are using 3D secure methods to protect transactions"
                        )
                      }}
                    </div>
                  </div>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  // import axios from "axios";
  
  export default {
    name: "PayoutModal",
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    props: [],
    data: function () {
      return {
        step: 2,
        amount: 5,
        method: "card",
        pan: "",
        formattedPan: "", // Formatted value
        expiration_date: "", // Expiration date (MM/YY)
        card_holdername: "", // Card holder name
        first_name: "",
        last_name: "",
        phone: "",
        country: "",
        selectedCountry: "",
        countryId: "",
        city: "",
        address: "",
        zip: "",
        countryOptions: [],
        countryOptionsId: [],
        countries: [],
        terms: "",
      };
    },
    computed: {
      requiredRegisterFieldsAreFilled() {
        let fieldsAreFilled;
        if (this.terms) {
          fieldsAreFilled = true;
        } else {
          fieldsAreFilled = false;
        }
        return fieldsAreFilled;
      },
    },
    mounted() {
      this.getCounties();
      this.$http
        .get(process.env.VUE_APP_API + "user/profile")
        .then((res) => {
          this.phone = res.data.phone;
          this.first_name = res.data.name;
          this.last_name = res.data.surname;
          this.countryId = res.data.country;
          this.city = res.data.city;
          this.address = res.data.address;
          this.zip = res.data.zip;
  
          if (this.countries && this.countries.length && this.countryId) {
            this.setCountry();
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignIn();
          }
        });
    },
    methods: {
        getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
          .then((res) => {
            let arr = res.data.payload;
            let newCountries = arr.map(function (item) {
              return item['title'];
            });
            let newCountriesId = arr.map(function (item) {
              return item['id'];
            });
            this.countryOptionsId = newCountriesId;
            this.countryOptions = newCountries;
            this.countries = arr;
            let mapped = this.countries.map(({
                                               id,
                                               title
                                             }) => ({
              id,
              title
            }));
            this.countries = mapped;

            if (this.countries && this.countries.length && this.countryId) {
              this.setCountry();
            }
          })
          .catch((res) => {
            if (res.response.status === 401) {
              this.$parent.openSignIn();
            }
          })
    },
      async proceed() {
        if (this.step === 1 || this.step === 2) {
          this.step = this.step + 1;
        } else {
          try {
            const [expiry_month, expiry_year] = this.expiration_date.split("/");
  
            const data = {
              amount: this.amount,
              currency: this.$parent.currencyCode,
              card_number: this.formattedPan.replace(/\s+/g, ""),
              expiry_month,
              expiry_year,
              cardholder_name: this.card_holdername,
            };
  
            await this.$http.post(process.env.VUE_APP_API + `payouts`, data);
  
            this.$parent.closeWithdraw();
          } catch (error) {
            if (error.response) {
              if (error.response.data.errors) {
                this.$refs.form.setErrors(error.response.data.errors);
              } else if (
                error.response.data.status === "ERROR" &&
                error.response.data.message
              ) {
                this.$parent.error = error.response.data.message;
              }
            }
          }
        }
      },
      formatCardHolderName(value) {
        // Удаляем все символы, кроме букв и пробелов
        value = value.replace(/[^A-Za-z\s]/g, "");
  
        // Удаляем лишние пробелы, оставляем только один пробел между словами
        value = value.replace(/\s+/g, " ");
  
        // Ограничиваем количество слов до двух
        const words = value.split(" ");
        if (words.length > 2) {
          value = words.slice(0, 2).join(" ");
        }
  
        return value.toUpperCase();
      },
      formatExpirationDate(value) {
        // Remove non-digit characters
        value = value.replace(/\D/g, "");
  
        // Limit to 4 digits (MMYY)
        if (value.length > 4) {
          value = value.slice(0, 4);
        }
  
        // Add a slash after the first two digits (MM/YY)
        if (value.length > 2) {
          value = value.slice(0, 2) + "/" + value.slice(2);
        }
  
        // Ensure valid month (01-12)
        const [month] = value.split("/");
        if (month && parseInt(month, 10) > 12) {
          value = "12" + value.slice(2);
        }
  
        return value;
      },
      resetErrorOnExpirationDateChange() {
        this.$parent.error = ""; // Сбрасываем ошибку
      },
      resetErrorOnCardNumberChange() {
        this.$parent.error = ""; // Сбрасываем ошибку
      },
      formatCardNumber(value) {
        // Remove non-digit characters
        value = value.replace(/\D/g, "");
  
        // If the input length exceeds 16, truncate it
        if (value.length > 16) {
          return value.slice(0, 16).replace(/(\d{4})(?=\d)/g, "$1 "); // Keep only the first 16 digits
        }
  
        // Add spaces every 4 digits
        return value.replace(/(\d{4})(?=\d)/g, "$1 ");
      },
    },
  };
  </script>
  