<template>
  <main class="main page-inside text-page contact-us-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title">
            {{ $t('Leave Feedback') }}
          </div>
          <div class="content form-fields-wrapper">
            <div class="fields">
              <label class="first">
                <div class="desc">{{ $t('Name') }}:</div>
                <input type="text" v-model="name"/>
              </label>
              <label class="second">
                <div class="desc">{{ $t('Email') }}:</div>
                <input type="text" v-model="email"/>
              </label>
              <label class="last">
                <div class="desc">{{ $t('Message') }}</div>
                <textarea v-model="message"></textarea>
              </label>
              <button :class="['button red long', {'disabled': $parent.isLoading }]" @click="submit">{{ $t('SEND') }}</button>
              <transition name="fade">
                <div v-if="$parent.successMessage" class="desc small green">{{$parent.successMessage}}</div>
              </transition>
              <transition name="slide">
                <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'ContactUs',
  props: [],
  components: {
    
  },
  data: function() {
    return {
      name: '',
      email: '',
      message: ''
    }
  },
  methods: {
    submit() {
      let data = {
        "name": this.name,
        "email": this.email,
        "message": this.message
      }
      this.$emit('sendContactForm', data);
    },
  }
}
</script>