<template>
  <div class="modal sign-modal reg-modal">
    <div class="overlay" @click="$emit('closeSignUpModal')"></div>
    <div class="wrapper">
      <div class="container">
        <a href="index.html" class="logo">
          <img src="./../img/logo.svg" class="img" />
        </a>
        <div class="form">
          <div class="form-wrapper">
            <img class="close" @click="$emit('closeSignUpModal')" src="./../img/prev.svg" />
            <div class="form-fields register-content">
              <div class="form-fields-wrapper">
                <div class="title">
                  <b>{{ $t("Sign Up") }}</b>
                </div>
                <div class="flex">
                  <div class="desc small">
                    <b>{{ $t('Already have an account?') }}</b>
                  </div>
                  <a @click="$parent.openSignInModal" class="desc small green2">
                    <b>{{ $t('Sign in') }}</b>
                  </a>
                </div>
                <label>
                  <div class="desc">{{ $t("First name") }}</div>
                  <input type="text" :placeholder="$t('First name')" v-model="name" />
                </label>
                <label>
                  <div class="desc">{{ $t("Last name") }}</div>
                  <input type="text" :placeholder="$t('Last name')" v-model="surname" />
                </label>
                <label v-if="countryOptions && countryOptions.length">
                  <div class="desc">*Phone</div>
                  <VuePhoneNumberInput ref="phoneInput" v-model="phone" :only-countries="countryOptions" :fetch-country="true" @update="phoneDataUpdate($event)" />
                </label>
                <label>
                  <div class="desc">*Email</div>
                  <input type="email" :placeholder="$t('Email')" v-model="email" />
                </label>
                <label>
                  <div class="desc">*Password</div>
                  <input type="password" :placeholder="$t('Password')" v-model="pass" />
                </label>
                <label>
                  <div class="desc">{{ $t("Confirm password") }}</div>
                  <input type="password" :placeholder="$t('Confirm password')" v-model="passConfirm" />
                </label>
                <div class="chekbox-container">
                  <label class="chekbox-label">
                    <div class="chekbox-label-wrapper">
                      <input type="checkbox" name="terms" v-model="terms" />
                      <div class="checkbox"></div>
                      <span class="title">{{ $t("I agree with") }} </span>
                      <a @click="$parent.goToPage('privacy')" class="title"> {{ $t("Privacy Policy") }}</a>
                      <span class="title"> {{ $t("and") }} </span>
                      <a @click="$parent.goToPage('terms')" class="title"> {{ $t("Terms and Conditions") }}</a>
                    </div>
                  </label>
                  <div class="cta-container">
                    <button class="button orange" @click="submitRegister">
                      <span>{{ $t("Sign up") }}</span>
                    </button>
                  </div>
                </div>
                <transition name="slide">
                  <div v-if="$parent.error || error" class="error-desc desc red">
                    {{ $parent.error ? $t($parent.error) : $t(error) }}
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

export default {
  name: "SignUpModal",
  props: [],
  components: {
    VuePhoneNumberInput
  },
  data: function () {
    return {
      email: "",
      pass: "",
      name: "",
      surname: "",
      phone: "",
      passConfirm: "",
      terms: false,
      error: null,
      phoneFull: "",
      countryOptions: []
    };
  },
  mounted() { 
    let self = this;
    this.getCountries();
    this.$nextTick(() => {
      setTimeout(function(){
        const inputElement = self.$refs.phoneInput.$el.querySelector('input[type="tel"]');
        if (inputElement) {
          inputElement.placeholder = self.$t('Phone number');
        }
      },200)
    });
  },
  computed: {
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if (this.name && this.surname && this.phone && this.email && this.pass && this.passConfirm && this.terms) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    },
  },
  methods: {
    getCountries() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          this.countryOptions = res.data.payload.map(obj => obj.iso);
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational;
    },
    validateName() {
      let nameRegex = /^[a-zA-Z]+$/;
      if (!nameRegex.test(this.name)) {
        this.error = this.$t('The name field must only contain letters');
        return false;
      }
      return true;
    },
    validateSurname() {
      let surnameRegex = /^[a-zA-Z]+$/;
      if (!surnameRegex.test(this.surname)) {
        this.error = this.$t('The surname field must only contain letters');
        return false;
      }
      return true;
    },
    validateEmail() {
      let emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      if (!emailRegex.test(this.email)) {
        this.error = this.$t('Please enter a valid email address');
        return false;
      }
      return true;
    },
    validatePassword() {
      let passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;
      if (!passwordRegex.test(this.pass)) {
        this.error = this.$t('The password must be at least 6 characters and include at least one uppercase letter, one lowercase letter, and one digit');
        return false;
      }
      return true;
    },
    validatePasswordConfirmation() {
      if (this.pass !== this.passConfirm) {
        this.error = this.$t('Password confirmation failed!');
        return false;
      }
      return true;
    },
    validateTerms() {
      if (!this.terms) {
        this.error = this.$t('You must agree to the terms and conditions to register');
        return false;
      }
      return true;
    },
    closeSignUpModal() {
      this.$emit("closeSignInModal");
    },
    openSignInModal() {
      this.$emit("openSignInModal");
    },
    submitRegister() {
      this.$parent.clearError();
      if (!this.validateName() || !this.validateSurname() || !this.validateEmail() || !this.validatePassword() || !this.validatePasswordConfirmation() || !this.validateTerms()) {
        return;
      }
      this.error = ""
      let regData = {
        name: this.name,
        surname: this.surname,
        email: this.email,
        phone: this.phoneFull,
        password: this.pass,
        passConfirm: this.passConfirm,
      };
        this.$emit("registration", regData);
    },
  },
};
</script>
