<template>
  <header class="header">
    <div class="wrapper">
      <div class="left">
        <router-link to="/" class="logo">
          <img src="./../img/logo.svg" class="img" />
        </router-link>
        <div @click="sellSkin" class="nav">
          <div class="nav__item">
            <a class="desc">
              <b>
                {{ $t("Sell skin") }}
              </b>
            </a>
          </div>
        </div>
        <router-link to="/products/all" class="desc">
          <b>{{ $t("Market") }}</b>
        </router-link>
        <a class="desc" @click="$parent.openHeroesModal">
          <b>{{ $t("Search by Hero") }}</b>
        </a>
        <div class="search-container">
          <input type="text" v-model="search" :placeholder="$t('Search')" />
          <img src="./../img/glass.svg" class="img" />
        </div>
      </div>
      <div class="right">
        <div
          v-if="currentLanguage && languages && languages.length > 1"
          class="select-container language-select-container"
        >
          <Select2
            v-model="language"
            class="select2 single currency-select"
            :options="languages"
            :placeholder="currentLanguageName"
            @select="selectLanguage($event)"
          />
        </div>
        <div
          class="select-container currency-select-container"
          v-if="currencyOptions && currencyOptions.length > 1"
        >
          <Select2
            class="select2 single currency-select"
            v-model="curr"
            @select="selectCurr($event)"
            :options="currencyOptions"
            :placeholder="curr"
          />
        </div>
        <div class="cash" v-if="$parent.isAuth" @click="$parent.openTopup">
          <div class="cash-wallet">
            <img src="./../img/wallet.svg" class="img" />
            <div class="desc">
              <b>
                <span class="currency">{{ $parent.currency }}</span>
                {{ balance }}
              </b>
            </div>
          </div>
          <a class="plus">
            <img src="./../img/plus.svg" class="img" />
          </a>
        </div>
        <a
          @click="$parent.openCart"
          v-if="$parent.isAuth"
          :class="['icon icon-cart round', { gray: !cartContents.length }]"
        >
          <img src="./../img/cart.svg" class="img" />
          <div v-if="cartContents.length" class="indicator">
            {{ cartContents.length }}
          </div>
        </a>
        <router-link
          to="/profile/settings"
          class="button-profile"
          v-if="$parent.isAuth"
        >
          <img src="./../img/user.svg" class="img" />
        </router-link>
        <div class="link-container" v-else>
          <div class="button" @click="$parent.openSignInModal">
            {{ $t("Login") }}
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Select2 from "vue3-select2-component";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "Header",
  props: [
    "cartContents",
    "currencyOptions",
    "currencyCode",
    "isAuth",
    "appBalance",
  ],
  components: {
    Select2,
  },
  data: function () {
    return {
      curr: "",
      balance: "0.00",
      search: "",
    };
  },
  computed: {
    ...mapState({
      languages: ({ app: { languages } }) =>
        languages.map(({ id, title: text }) => ({ id, text })),
      currentLanguage: (state) => state.app.currentLanguage,
    }),
    ...mapGetters("app", ["currentLanguageName"]),
    language: {
      set(langId) {
        this.changeLocale(langId);
      },
      get() {
        return this.currentLanguage;
      },
    },
  },
  watch: {
    currencyCode(newValue) {
      this.curr = newValue;
    },
    isAuth() {
      this.getBalance();
    },
    appBalance() {
      this.getBalance();
    },
    search(newVal) {
      this.$emit("setSearch", newVal);
    },
  },
  mounted() {
    this.curr = this.currencyCode;
  },
  methods: {

    sellSkin() {
      if (this.$parent.isAuth) {
        this.$parent.openSellSkinModal();
      } else {
        this.$parent.openSignIn();
      }
    },
    selectLanguage(event) {
      this.changeLocale(parseInt(event.id));
      this.$parent.getTextPages();
      if (this.$route.name == "TextPage") {
        this.$parent.getTextPageContent(this.$route.params.id);
      }
    },
    getBalance() {
      if (this.$parent.isAuth) {
        this.$http
          .get(
            process.env.VUE_APP_API +
              "user/profile?currency=" +
              this.$parent.currencyCode
          )
          .then((res) => {
            this.balance = res.data.balance;
          })
          .catch((res) => {
            if (res.response.status === 401) {
              this.$parent.openSignIn();
            }
          });
      }
    },
    selectCurr(event) {
      this.curr = event.text;
      this.changeCurrency(event.text);
      localStorage.setItem("currency", event.text);
    },
    changeCurrency(item) {
      this.$emit("changeCurrency", item);
    },
    selectCurrency(event) {
      this.setCurrency(event.id);
    },
    openSaleSkinModal() {
      this.$emit("openSaleSkinModal");
    },
    logout() {
      this.$emit("logout");
    },
    ...mapMutations("app", ["setCurrentLanguage"]),
    ...mapActions("app", ["changeLocale"]),
  },
};
</script>
