import Vue from "vue";
import VueRouter from "vue-router";
import PageNotFound from "./pages/PageNotFound.vue";
import Home from "./pages/Home.vue";
import ProductListPage from "./pages/ProductListPage.vue";
import NewArrivals from "./pages/NewArrivals.vue";
import Sale from "./pages/Sale.vue";
import Recommend from "./pages/Recommend.vue";
import Profile from "./pages/Profile.vue";
import SellOffers from "./pages/SellOffers.vue";
import ProductPage from "./pages/ProductPage.vue";
import PaymentSuccessPage from "./pages/PaymentSuccessPage.vue";
import PaymentFaliPage from "./pages/PaymentFaliPage.vue";
import PaymentPendingPage from "./pages/PaymentPendingPage.vue";
import TextPage from "./pages/TextPage.vue";
import EmailVerification from "./pages/EmailVerification.vue";
import ContactUs from "./pages/ContactUs.vue";

Vue.use(VueRouter);

export default new VueRouter({
  routes: [
    { path: "/", redirect: "/home" },
    {
      path: "/home",
      name: "Home",
      component: Home,
    },
    {
      path: "/contacts",
      name: "ContactUs",
      component: ContactUs,
    },
    {
      path: "/products",
      name: "ProductListPage",
      component: ProductListPage,
    },
    {
      path: "/products/:type",
      name: "ProductListPage",
      component: ProductListPage,
    },
    {
      path: "/new-arrivals",
      name: "NewArrivals",
      component: NewArrivals,
    },
    {
      path: "/sale",
      name: "Sale",
      component: Sale,
    },
    {
      path: "/recommend",
      name: "Recommend",
      component: Recommend,
    },
    {
      path: "/profile/:nav",
      name: "Profile",
      component: Profile,
    },
    {
      path: "/verify-email",
      name: "EmailVerification",
      component: EmailVerification,
    },
    {
      path: "/sell-offers",
      name: "SellOffers",
      component: SellOffers,
    },
    {
      path: "/product/:id",
      name: "ProductPage",
      component: ProductPage,
    },
    {
      path: "/success-payment",
      name: "PaymentSuccessPage",
      component: PaymentSuccessPage,
    },
    {
      path: "/fail-payment",
      name: "PaymentFaliPage",
      component: PaymentFaliPage,
    },
    {
      path: "/processing-payment",
      name: "PaymentPendingPage",
      component: PaymentPendingPage,
    },
    {
      path: "/pages/:id",
      name: "TextPage",
      component: TextPage,
    },
    {
      path: "*",
      name: "PageNotFound",
      component: PageNotFound,
      props: { title: "PageNotFound" },
    },
  ],
});
